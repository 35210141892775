import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import API from '../../utils/api';
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from '@material-ui/core/styles';
// @material-ui/icons
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import brown from '@material-ui/core/colors/brown';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/Block';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Moment from 'moment';

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/ultragreen-logo-big.png";
import miniUGCimage from "assets/img/UltraGreen_logo_only_mini_web.png";

import styles from "assets/jss/material-kit-react/views/SearchPageStyle.js";

const useStyles = makeStyles(styles);

const TableCellCustom = withStyles(theme => ({
    head: {
      backgroundColor: '#38c754',
      color: theme.palette.common.white,
    //   fontSize: 14,
    },
    body: {
    //   fontSize: 12,
    },
  }))(TableCell);

const CheckCircleOutlineIconGreen = withStyles({
    root: {
        color: green[400],
    },
})((props) => <Tooltip title="Approved by UltraGreen Certification standard!" placement="right-start"><CheckCircleOutlineIcon color="default" {...props} /></Tooltip>);

const BlockIconRed = withStyles({
    root: {
        color: red[400],
    },
})((props) => <Tooltip title="Prohibited by UltraGreen Certification standard!" placement="right-start"><BlockIcon color="default" {...props} /></Tooltip>);

const HelpOutlineIconBrown = withStyles({
    root: {
        color: brown[200],
    },
})((props) => <Tooltip title="Custom classification needed!" placement="right-start"><HelpOutlineIcon color="default" {...props} /></Tooltip>);

// const spanStyleUG = {
//   fontFamily: "Menco",
//   fontSize: "1.1rem",
// };

export default function SearchPage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );
    const [state, setState] = useState({
        pageNum: '0',
        loading: false,
        searchResult: [],
        resultSizeIngSub: 0,
        resultSizeBrands: 0,
        searchText: '',
        resultInfoText: 'Start searching!',
    });
    const [count, setCount] = useState(0);

    const [stateQuery, setStateQuery] = useState('')
    
    const handleChangeText = (event) => {
        // console.log(event.target.id);
        // console.log(event.target.value);
        setState({ ...state, [event.target.id]: event.target.value });
    };

    const onKeyUp = (event) => {
        if (event.charCode === 13) {
            // console.log("Press enter and start search...");
            startSearching();
        }
    }

    const handleClickSearch = (event) => {
        // console.log("Click search...");
        startSearching();
    };

    function startSearching() {
        // console.log("Start searching...");
        const query = state.searchText;
        setState({ loading: true });
        setStateQuery(query);
    };

    useEffect(() => {
        API.post( '/ultragreen/hb' ).then(
            function( response ) {
            //   console.log( response );
            } )
            .catch( function( error ) {
                // console.log( error );
            } );
    }, []);

    useEffect(() => {
        const query = stateQuery;
        setCount(count + 1);
        console.log( count );
        if (query === '' || query === undefined || query === null || count === 0) {
            setState({
                loading: false,
                searchResult: [],
                resultSizeIngSub: 0,
                resultSizeBrands: 0,
                searchText: '',
                resultInfoText: 'Start searching!',
            })
            return;
        }
        if (query.length < 4 && query.length > 0) {
            setState({
                resultInfoText: 'Please type longer search query!',
            })
            return;
        }
        API.get('search/all/' + stateQuery + '/' + '0' + '/50/cosahh.com').then(
            function (response) {
                var allRepos = response.data;
                var ingSubList = response.data.resultIngSubs;
                var brandsList = response.data.resultBrands;
                const sizeIngSub = ingSubList.length;
                const sizeBrands = brandsList.length;
                setState({
                    loading: false,
                    searchResult: allRepos,
                    resultSizeIngSub: sizeIngSub,
                    resultSizeBrands: sizeBrands,
                    searchText: '',
                    resultInfoText: '',
                })

                if (sizeIngSub === 0 && sizeBrands === 0) {
                    setState({
                        resultInfoText: 'No results.',
                    })
                }


                console.log(response);
                console.log("Size ingsub: " + sizeIngSub);
                console.log("Size brands: " + sizeBrands);
            })
            .catch(function (error) {
                // setState({ ...state,
                //   applyform: false,
                //   isValid: false,
                //  });
                // console.log(error);
            })
            // setCount(count + 1);
    }, [stateQuery]);

    const ingsubElements = (state.loading === false && state.searchResult.resultIngSubs !== undefined) ? (state.searchResult.resultIngSubs.map(n => {
        return (
            <TableRow key={n.id} className={classes.row}>
                <TableCell component="th" scope="row" className={classes.cell}>
                    <a style={{color: '#555'}} href={"https://www.skinlyzer.com/ingredient/" + n.authId} target="_blank">{n.inci}</a>
                </TableCell>
                <TableCell className={classes.cell}>{n.cas}</TableCell>
                <TableCell className={classes.cell}>{n.ec}</TableCell>
                <TableCell className={classes.cellHead}>
                {n.ultraGreenScore === 0 && n.ultraGreenScore !== undefined &&
                        <HelpOutlineIconBrown />
                    }
                    {n.ultraGreenScore === 1 && <CheckCircleOutlineIconGreen />}
                    {n.ultraGreenScore === 2 && <BlockIconRed />}
                </TableCell>
                <TableCell className={classes.cellHead}>
                    {n.ultraGreenScoreDetergent === 0 && n.ultraGreenScoreDetergent !== undefined &&
                        <HelpOutlineIconBrown />
                    }
                    {n.ultraGreenScoreDetergent === 1 && <CheckCircleOutlineIconGreen />}
                    {n.ultraGreenScoreDetergent === 2 && <BlockIconRed />}
                </TableCell>
            </TableRow>
        );
    })) : '';

    const brandsElements = (state.loading === false && state.searchResult.resultBrands !== undefined) ? 
        (state.searchResult.resultBrands.map(b => {
        const miniUCGlogo = <img src={miniUGCimage} width="80"/>;
        var ugcString = '';
        if (b.ultraGreenCert === 1) {
            ugcString = <div>{miniUCGlogo}<br/>Audited cosmetic brand
            <br/>since {Moment(b.ultraGreenCertAuditDate).format('YYYY-MM-DD')}.</div>;
        }
        if (b.ultraGreenCert === 2) {
            ugcString = <div>{miniUCGlogo}<br/>Audited cosmetic brand & manufacturer
            <br/>since {Moment(b.ultraGreenCertAuditDate).format('YYYY-MM-DD')}.</div>;
        }
        if (b.ultraGreenCert === 3) {
            ugcString = <div>{miniUCGlogo}<br/>Audited detergent brand
            <br/>since {Moment(b.ultraGreenCertAuditDate).format('YYYY-MM-DD')}.</div>;
        }
        if (b.ultraGreenCert === 4) {
            ugcString = <div>{miniUCGlogo}<br/>Audited detergent brand & manufacturer
            <br/>since {Moment(b.ultraGreenCertAuditDate).format('YYYY-MM-DD')}.</div>;
        }
        if (b.ultraGreenCert === 5) {
            ugcString = <div>{miniUCGlogo}<br/>Audited cosmetic and detergent brand
            <br/>since {Moment(b.ultraGreenCertAuditDate).format('YYYY-MM-DD')}.</div>;
        }
        if (b.ultraGreenCert === 6) {
            ugcString = <div>{miniUCGlogo}<br/>Audited cosmetic and detergent manufacturer & brand
            <br/>since {Moment(b.ultraGreenCertAuditDate).format('YYYY-MM-DD')}.</div>;
        }
        if (b.ultraGreenCert === 404) {
            ugcString = <div>Certification expired
            <br/>from {Moment(b.ultraGreenCertAuditDate).format('YYYY-MM-DD')}.</div>;
        }
        if (b.ultraGreenCert === 909) {
            ugcString = <div>Certification withdrawn for breach of the rules
            <br/>from {Moment(b.ultraGreenCertAuditDate).format('YYYY-MM-DD')}.</div>;
        }

        return (
            <TableRow key={b.id} className={classes.row}>
                <TableCell className={classes.cell}>
                    <img src={'https://storage.googleapis.com/cosahh-149311.appspot.com/cosahh/'+b.logoUrl} width="100"/>
                        <a href={'https://skinlyzer.com/brand/' + b.authId} target="_blank">&nbsp;{b.name}</a>
                </TableCell>
                <TableCell component="th" scope="row" className={classes.cell}>[HU] {b.description}</TableCell>
                <TableCell className={classes.cell} className={classes.cellHead}>{ugcString}</TableCell>
            </TableRow>
        );
    })) : '';

    return (
        <div>
            <Header
                color="transparent"
                brand="UltraGreen Database"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/bg-granules.jpg")} />
            <div className={classNames(classes.main)}>
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={8}>
                            <div className={classes.profile}>
                                <div>
                                    <img src={profile} alt="..." className={imageClasses} />
                                </div>
                                <div className={classes.name}>
                                    <div className={classes.typo}>
                                        <h2 className={classes.title}>UltraGreen Certification Database</h2>
                                    </div>
                                    <div className={classes.typo}>
                                        <p className={classes.description}>You can search cosmetic and detergent ingredients, 
                                        which can be or not used in UltraGreen Certified products, and also you can find your favourite brands certification status.</p>
                                    </div>
                                </div>
                            </div>
                        </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={12}>
                            <div style={{ paddingBottom: 50 }}>
                                <TextField
                                    id="searchText"
                                    label="Search brands, ingredients e.g. Benzyl Alcohol or by cas, ec numbers."
                                    onChange={handleChangeText}
                                    value={state.searchText}
                                    onKeyPress={onKeyUp}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleClickSearch} className={classes.iconButton} aria-label="search">
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            {state.resultSizeBrands > 0 &&
                                <div>
                                    <Typography variant="h5" className={classes.subTitle}>Brands</Typography>
                                    <Table className={classes.table}>
                                        <TableHead className={classes.tableHead}>
                                            <TableRow>
                                                <TableCellCustom style={{ width: "30%" }}>Brand Name</TableCellCustom>
                                                <TableCellCustom style={{ width: "50%" }}>Description</TableCellCustom>
                                                {/* <TableCellCustom>Homepage</TableCellCustom> */}
                                                <TableCellCustom className={classes.cellHead}>Certification Status</TableCellCustom>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {brandsElements}
                                        </TableBody>
                                    </Table>
                                    <Typography variant="h5" className={classes.subTitle2}></Typography>
                                </div>
                            }
                            {state.resultSizeIngSub > 0 &&
                                <div>
                                    <Typography variant="h5" className={classes.subTitle}>Ingredients</Typography>
                                    <Table className={classes.table}>
                                        <TableHead className={classes.tableHead}>
                                            <TableRow>
                                                <TableCellCustom style={{ width: "50%" }}>INCI</TableCellCustom>
                                                <TableCellCustom>CAS</TableCellCustom>
                                                <TableCellCustom>EC</TableCellCustom>
                                                <TableCellCustom className={classes.cellHead}>Cosmetic Status</TableCellCustom>
                                                <TableCellCustom className={classes.cellHead}>Detergent Status</TableCellCustom>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {ingsubElements}
                                        </TableBody>
                                    </Table>
                                </div>
                            }
                            {(state.resultSizeIngSub > 0 || state.resultSizeBrands > 0) &&
                                    <div style={{ paddingTop: 20 }}>
                                        <Typography variant="caption" display="block" gutterBottom>You should more specific the ingredient name if you don't get the expected result.</Typography>
                                    </div>
                            }
                            {state.loading && <LinearProgress />}
                        </GridItem>
                    </GridContainer>
                    <div style={{ paddingTop: 20, paddingBottom: 50 }}>
                        <Typography align="center" variant="body1" gutterBottom>{state.resultInfoText}</Typography>
                    </div>
                    <div style={{ paddingTop: 50, paddingBottom: 50 }}>
                        <Typography variant="body1" gutterBottom>Important notice</Typography>
                        <Typography variant="caption" display="block" gutterBottom>Please note that ingredient assigned with an INCI name that
                        appears in the results section of this page does not mean it is to be used in cosmetic products
                    nor approved for such use.</Typography>
                        <Typography variant="caption" display="block" gutterBottom>In addition, the use of any ingredient in cosmetic products
                    must be supported by a safety assessment of the product.</Typography>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
