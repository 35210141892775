import { container, title } from "assets/jss/material-kit-react.js";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const searchPageStyle = {
  container,
  profile: {
    textAlign: "center",
    "& img": {
      maxWidth: "160px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)",
    }
  },
  description: {
    margin: "1.071rem auto 0",
    maxWidth: "600px",
    color: "#555",
    textAlign: "center !important"
  },
  name: {
    marginTop: "-80px"
  },
  ...imagesStyle,
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  subTitle: {
    marginBottom: "10px",
  },
  subTitle2: {
    marginBottom: "40px",
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999"
  },
  tableCompare: {
    textAlign: 'center',
  },
  tableHead: {
    textAlign: 'center',
    fontWeight: "bold",
    marginTop: "20px",
    backgroundColor: '#82ee94',
    color: 'white !important',
  },
  head: {
    color: 'white !important',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fafafa',
    },
  },
  cell: {
    fontSize: '0.85rem',
  },
  cellHead: {
    fontSize: '0.85rem',
    textAlign: "center",
  },
  navWrapper: {
    margin: "20px auto 50px auto",
    textAlign: "center",
  },
  // heading: {
  //   fontSize: '1.0rem',
  //   flexBasis: '100.00%',
  //   flexShrink: 0,
  // },
  // secondaryHeading: {
  //   fontSize: '0.8rem',
  //   flexBasis: '20.00%',
  //   color: 'gray',
  // },
  // sthirdyHeading: {
  //   fontSize: '0.8rem',
  //   color: 'gray',
  // },
};

export default searchPageStyle;
