import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import API from '../../utils/api';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/ultragreen-logo-big.png";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

import image1 from "assets/img/globe-02.png";

const useStyles = makeStyles(styles);

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function ContactPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    cosmetic: false,
    detergent: false,
    packaging: false,
    applyform: false,
    isValid: false,
    data: {
      id: null, name: '', email: '', phone: '', message: '', categories: '',
    },
  });

  const [stateData, setStateData] = React.useState({
    data: {
      id: null, name: '', email: '', phone: '', message: '', categories: '',
    },
  });

  useEffect(() => {
    validateField();
  }, [state]);

  useEffect(() => {
    serviceHB();
  }, []);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeText = (event) => {
    // console.log( event.target.id );
    // console.log( event.target.value );
    setState({ ...state, [event.target.id]: event.target.value });
  };

  const handleChangeValidate = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    // console.log(state.packaging);
    validateField();
  };

  const handleClickSend = (event) => {
    sendForm();
  };

  const validateField = () => {
    var valid = true;
    if (state.name.length < 7) {
      valid = false;
    }
    if (state.email.length < 7) {
      valid = false;
    }
    if (!state.email.includes('@')) {
      valid = false;
    }
    if (state.message.length < 7) {
      valid = false;
    }
    if (!state.applyform) {
      valid = false;
    }
    if (!state.cosmetic && !state.detergent && !state.packaging) {
      valid = false;
    }
    if (valid) {
      state.isValid = true;
    } else {
      state.isValid = false;
    }
    if (state.isValid) {
      var cat = '';
      if (state.cosmetic) cat = cat + 'cosmetic ';
      if (state.detergent) cat = cat + 'detergent ';
      if (state.packaging) cat = cat + 'packaging ';
      setStateData({ ...stateData,
      data: {
        id: null,
        name: state.name,
        email: state.email,
        phone: state.phone,
        message: state.message,
        categories: cat,
      }});
    }
    // console.log("State name: " + stateData.data.categories);
    // console.log("Valid: " + valid);
  }

  async function sendForm() {
    setState({ ...state,
      name: '',
      email: '',
      phone: '',
      message: '',
      cosmetic: false,
      detergent: false,
      packaging: false,
      applyform: false,
      isValid: false,
     });

    // console.log( this.state.subs );
    // const history = this.props.history;
    if ( state.isValid ) {
      // console.log( stateData.data );
        API.post( '/ultragreen/contactform', stateData.data ).then(
            function( response ) {
                // console.log( response );
                //                    this.setState({
                //                        progress: false,
                //                    });
                if ( response.data === 'successful' ) {
                  // console.log( response.data );
                    setState({ ...state,
                      name: '',
                      email: '',
                      phone: '',
                      message: '',
                      cosmetic: false,
                      detergent: false,
                      packaging: false,
                      applyform: false,
                      isValid: false,
                     });
                     setOpen(true);
                }
                if ( response.data === 'too many people' ) {
                    // console.log( "Már valalki beelőzött jelentkezésben!" );
                }
            } )
            .catch( function( error ) {
              setState({ ...state,
                applyform: false,
                isValid: false,
               });
                // console.log( error );
            } );
    }
}

async function serviceHB() {
  API.post( '/ultragreen/hb' ).then(
    function( response ) {
      // console.log( response );
    } )
    .catch( function( error ) {
      setState({ ...state,
        applyform: false,
        isValid: false,
       });
        // console.log( error );
    } );
}

const handleDialogClose = () => {
  window.location.reload(false);
  setOpen(false);
};

  const { cosmetic, detergent, packaging, applyform } = state;
  const error = [cosmetic, detergent, packaging].filter((v) => v).length < 1;

  return (
    <div>
      <Header
        color="transparent"
        brand="UltraGreen Certifications"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg04.jpg")} />
      <div className={classNames(classes.main)}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <div className={classes.profile}>
              <div>
                <img src={profile} alt="..." className={imageClasses} />
              </div>
            </div>
          </GridItem>
        </GridContainer>
        <div className={classes.container}>
          <form className={classes.root} noValidate autoComplete="off">
            <GridContainer justify="center">
              <GridItem cs={8} sm={8} md={8}>
                <h2 className={classes.title}>Contact Us</h2>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <TextField
                  id="name"
                  label="Name"
                  required
                  // style={{ margin: 8 }}
                  // placeholder="Name"
                  // helperText="Full width!"
                  onChange={handleChangeText}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                // InputLabelProps={{
                //   shrink: true,
                // }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={5} sm={5} md={5}>
                <TextField
                  id="email"
                  label="Email"
                  required
                  // style={{ margin: 8 }}
                  // placeholder="Name"
                  // helperText="Full width!"
                  onChange={handleChangeText}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                // InputLabelProps={{
                //   shrink: true,
                // }}
                />
              </GridItem>
              <GridItem xs={3} sm={3} md={3}>
                <TextField
                  id="phone"
                  label="Phone number"
                  // style={{ margin: 8 }}
                  // placeholder="Name"
                  // helperText="Full width!"
                  onChange={handleChangeText}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                // InputLabelProps={{
                //   shrink: true,
                // }}
                />
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <TextField
                  id="message"
                  label="Your message"
                  margin="normal"
                  onChange={handleChangeText}
                  multiline
                  fullWidth
                  rows={8}
                  defaultValue=""
                  variant="outlined"
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={8} sm={8} md={8}>
                <FormControl required error={error} component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">Select product categories</FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={<GreenCheckbox checked={cosmetic} onChange={handleChange} name="cosmetic" />}
                      label="Cosmetic"
                    />
                    <FormControlLabel
                      control={<GreenCheckbox checked={detergent} onChange={handleChange} name="detergent" />}
                      label="Detergent"
                    />
                    <FormControlLabel
                      control={<GreenCheckbox checked={packaging} onChange={handleChange} name="packaging" />}
                      label="Packaging"
                    />
                  </FormGroup>
                  {/* <FormHelperText>Please select min. one field!</FormHelperText> */}
                </FormControl>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <br />
                <FormControlLabel
                  control={<Checkbox checked={state.applyform} onChange={handleChangeValidate} name="applyform" />}
                  label="I have read and agree to the Privacy Policy!"
                />
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <Link href="/privacy" target="_blank">Click here to read the Privacy Policy</Link>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <Button onClick={handleClickSend} disabled={!state.isValid && !state.applyform} variant="contained" color="primary" className={classes.sendButton}>
                  Send message
                </Button>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <div className={classes.profile2}>
                  <div>
                    <img src={image1} alt="..." />
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </form>
        </div>
        <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Thank you!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your message has been successful send!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      </div>
      <Footer />
    </div>
  );
}
