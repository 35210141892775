import React, { useState, useEffect } from "react";
import API from '../../utils/api';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import axios from 'axios';

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/ultragreen-logo-big.png";

import PromoterSection from "../Components/Sections/PromoterSection.js";

import styles from "assets/jss/material-kit-react/views/cosCertPage.js";


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Certificate(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [cert, setCert] = useState();

  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  useEffect(() => {
    axios.get('/infiniteCertificateTemplate.md').then(
      function (response) {
        setCert(response.data);
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <Header
        color="transparent"
        brand="UltraGreen Cosmetics"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg-cosmetic.jpg")} />
      <div className={classNames(classes.main)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  {cert && <ReactMarkdown className={classes.mark} children={cert} remarkPlugins={[remarkGfm]} />}
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.mark}>
              {cert && <ReactMarkdown className={classes.mark} children={cert} remarkPlugins={[remarkGfm]} />}
            </div>
          </div>
        </div>
        <PromoterSection />
      </div>
      <Footer />
    </div>
  );
}
