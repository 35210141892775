import React from "react";
import { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/ultragreen-logo-big.png";
import dosing from "assets/img/dosing.jpg";

import PromoterSection from "../Components/Sections/PromoterSection.js";

import styles from "assets/jss/material-kit-react/views/cosCertPage.js";

const useStyles = makeStyles(styles);

export default function HowMuchLaundryDetergentShouldYouUse(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

      useEffect(() => {
        document.title = "How Much Laundry Detergent Should You Use";
      }, []);

  return (
    <div>
      <Header
        color="transparent"
        brand="UltraGreen Cosmetics"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg-detergent.jpg")} />
      <div className={classNames(classes.main)}>
        <div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <div className={classes.profile}>
                <div>
                  <img src={profile} alt="..." className={imageClasses} />
                </div>
                <div className={classes.name}>
                  <div className={classes.typo}>
                    <h2 className={classes.title}>
                      {" "}
                      Finding the Perfect Balance: How Much Laundry Detergent
                      Should You Use?
                    </h2>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.container}>
          <div>
            <p>
              Laundry day is a chore that most of us tackle regularly, but do we
              really know how much laundry detergent to use? Using the right
              amount is not only essential for clean and fresh-smelling clothes
              but also for maintaining the longevity of your washing machine.
              Let's delve into the dos and don'ts of detergent dosing to ensure
              your laundry routine is both effective and economical.
            </p>

            <p>
              <strong>1. Read the Label:</strong>
            </p>

            <p>
              The first step to determining the right amount of laundry
              detergent is to read the product label. Different detergents have
              varying concentrations, so it's crucial to follow the
              manufacturer's guidelines. The recommended amount is typically
              based on the size of your load and the level of soil.
            </p>

            <div>
              <img src={dosing} alt="..." />
            </div>

            <p>
              <strong>2. Consider Load Size:</strong>
            </p>

            <p>
              One of the primary factors influencing detergent usage is the size
              of your laundry load. Overloading your machine can lead to
              insufficient cleaning, while too much detergent in a small load
              can result in soap residue on your clothes. For a standard-sized
              load, a detergent cap or line indicator is usually provided to
              guide you.
            </p>

            <p>
              <strong>3. Level of Soiling:</strong>
            </p>

            <p>
              The dirtier your clothes, the more detergent you may need. Heavy
              stains or heavily soiled items may require a higher detergent
              dose. Pre-treating stains can also reduce the overall amount of
              detergent needed for satisfactory cleaning.
            </p>

            <p>
              <strong>4. High-Efficiency Machines:</strong>
            </p>

            <p>
              If you're using a high-efficiency (HE) washing machine, it's
              crucial to choose a detergent specifically designed for these
              machines. HE detergents are formulated to produce fewer suds,
              which is important for the efficient operation of these machines.
              Using a regular detergent in an HE machine can lead to excess suds
              and potential issues.
            </p>

            <p>
              <strong>5. Soft and Hard Water:</strong>
            </p>

            <p>
              The hardness of your water can affect the performance of your
              detergent. In areas with hard water, you might need to use more
              detergent to compensate for mineral content. Conversely, in soft
              water areas, you may need less detergent for effective cleaning.
            </p>

            <p>
              <strong>6. Environmentally Friendly Options:</strong>
            </p>

            <p>
              For those environmentally conscious, there are eco-friendly and
              concentrated detergents available. While these options often
              require smaller doses, it's important to follow the manufacturer's
              recommendations to ensure proper cleaning and stain removal.
            </p>

            <p>
              <strong>7. Maintenance and Residue:</strong>
            </p>

            <p>
              Using too much detergent can lead to residue buildup in your
              machine and on your clothes. This residue not only affects the
              cleanliness of your laundry but can also contribute to unpleasant
              odors. Over time, it may even cause damage to your washing
              machine.
            </p>

            <p>
              <strong>Conclusion:</strong>
            </p>

            <p>
              Achieving the right balance when it comes to using laundry
              detergent is key to clean, fresh, and well-maintained clothes. By
              considering factors such as load size, soil level, water hardness,
              and machine type, you can optimize your detergent usage for both
              effectiveness and efficiency. Always follow the guidelines
              provided by the detergent manufacturer and your washing machine's
              manual to ensure a successful laundry routine that keeps your
              clothes looking their best.
            </p>
            <p>Preserving Nature, Defending Future.</p>
          </div>
        </div>
        <PromoterSection />
      </div>
      <Footer />
    </div>
  );
}
