import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Button from '@material-ui/core/Button';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);
// const spanStyleUG = {
//   fontFamily: "Menco",
// };

export default function PromoterSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={8} sm={8} md={8}>
          <div className={classes.title}>
            <h4>For more information please visit our contact page!</h4>
            <Button
              href="https://docs.google.com/forms/d/e/1FAIpQLScyPa9F_sWockEfvxPys0tfTxqi-L5vw4MC1Q23l-quu_qFhQ/viewform?usp=sf_link"
              variant="contained"
              color="primary"
              target="_blank"
              style={{ color: "white" }}
            >
              Go to contact page
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
