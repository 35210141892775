import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/ultragreen-logo-big.png";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

import image1 from "assets/img/globe-02.png";

const useStyles = makeStyles(styles);


export default function PrivacyPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  return (
    <div>
      <Header
        color="transparent"
        brand="UltraGreen Certifications"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg04.jpg")} />
      <div className={classNames(classes.main)}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <div className={classes.profile}>
              <div>
                <img src={profile} alt="..." className={imageClasses} />
              </div>
            </div>
          </GridItem>
        </GridContainer>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem cs={8} sm={8} md={8}>
              <h1>Data protection policy related to the use of the Website</h1>
              <p>UltraGreen will make sure that the personal data it collects from its Website users when they are using the
              present Website or when they contact UltraGreen by phone, email or via the contact form available on the
    Website, are processed in accordance with the General Regulations on Data Protection (GDPR).</p>
              <p><strong>PREAMBLE</strong></p>
              <p>This personal data protection policy related to the use of the Website is intended to describe:</p>
              <ul>
                <li>the types of personal data that UltraGreen may collect during the use of the Website, or during a first
        contact by phone, email or via the contact form available on the present Website,</li>
                <li>the purposes of data processing and their legal grounds,</li>
                <li>the possible recipients of the personal data,</li>
                <li>the storage period of the personal data, as well as,</li>
                <li>the rights attached to these personal data.</li>
              </ul>
              <p>UltraGreen has also developed a specific policy regarding the protection of the personal data of its
    partners, including (prospective) clients. This policy is available upon request.</p>
              <p><strong>1. TYPES OF PERSONAL DATA AND SOURCES</strong></p>
              <p>UltraGreen will make sure to only collect and process data which are strictly necessary for the purpose
    pursued (minimization of data).</p>
              <p>When accessing and using the Website, or when contacting UltraGreen by phone, email or via the form
    available on the Website, UltraGreen may have access to the following data:</p>
              <ul>
                <li>Your name (s) and first name (s)</li>
                <li>Your personal and / or professional email address</li>
                <li>Your personal and / or professional phone number</li>
                <li>Your postal address / your location </li>
              </ul>
              <p><strong>2. PURPOSES AND LAWFULNESS OF PROCESSING PERSONAL DATA</strong></p>
              <p>The treatments implemented by UltraGreen respond to an explicit, legitimate and determined purpose.</p>
              <p>Your data are processed by UltraGreen for the following purposes:</p>
              <ul>
                <li>in order to respond to your requests sent via the contact form, by email or indicated by phone. In
                this case, the processing of your personal data is part of a legitimate interest UltraGreen pursues,
        namely, to follow up on your request; </li>
                <li>in order to ensure that our Website works in an optimal way, by using cookies. We need to obtain
                your consent in order to allow cookies to function. If you want to modify the settings with regard
        to these cookies, you can consult our <a href="http://www.ultragreencert.com/cookies-management-policy">specific
                  policy for the
            management of cookies available on the Website. </a></li>
              </ul>
              <p><strong>3. HOW LONG DO WE STORE YOUR PERSONAL DATA?</strong></p>
              <p>UltraGreen stores your data as long as necessary to fulfill the objective pursued at the time of their
              collection. In case UltraGreen would need your personal data for any other purpose than the one for which
              they were originally collected, UltraGreen will inform you and, if necessary, ask you for your prior
    consent. </p>
              <p><strong>4.</strong> <strong>TRANSFER OF YOUR DATA OUTSIDE THE EU</strong></p>
              <p>UltraGreen will transfer your personal data to entities of the UltraGreen Group or to subcontracting companies
    located outside the European Union only in case this is necessary in order to process your request. </p>
              <p><strong>5. YOUR RIGHTS RELATING TO YOUR PERSONAL DATA AND THE APPLICABLE PROCEDURE</strong></p>
              <p>Pursuant to the General Data Protection Regulation, you have the right to access, rectify, erase and
              restrict processing of your personal data. You also have a right to portability of your personal data
              and a right to define the fate of your data after your death. You can exercise these rights by
    contacting our Data Protection Officer (DPO):</p>
              <ul>
                <li>by email : info@ultragreencert.com</li>
                <li>by post :<strong><br />
            UltraGreen Kft.<br /></strong>
        Batthyány utca 35/B. <br />
        Budapest <br />
        Hungary<br />
        1039</li>
              </ul>
              <p>All requests must be accompanied by a valid proof of identity.</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
            <div className={classes.profile2}>
              <div>
                <img src={image1} alt="..." />
              </div>
            </div>
          </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
