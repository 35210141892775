import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/page1Style.js";
import logo from "assets/img/logo-pure-big.png";

const useStyles = makeStyles(styles);

const spanStyleUG = {
  fontFamily: "Menco",
};

export default function Page1() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <img src={logo} alt="..." className={imageClasses} />
          <h2 className={classes.title}><span style={spanStyleUG}>UltraGreen Certificates</span></h2>
          <h4 className={classes.description}>
          With thousands of consumer products on the market, it can be overwhelming 
          to know which ones are safer and healthier for your family. The UltraGreen  
          Certificates does the work for you. When you see the UltraGreen logo on a product, 
          you can be sure it's the best for you and the enviroment.
          </h4>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>

          </GridItem>
          <GridItem xs={12} sm={12} md={4}>

          </GridItem>
          <GridItem xs={12} sm={12} md={4}>

          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
