import React from "react";
import { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import Icon from '@material-ui/core/Icon';
import green from '@material-ui/core/colors/green';
// import deepOrange from '@material-ui/core/colors/deepOrange';
import brown from '@material-ui/core/colors/brown';
import profile from "assets/img/ultragreen-logo-big.png";

import PromoterSection from "../Components/Sections/PromoterSection.js";

import styles from "assets/jss/material-kit-react/views/detCertPage.js";

const useStyles = makeStyles(styles);

const spanStyleUG = {
  fontFamily: "Menco",
  fontSize: "1.1rem",
};

export default function DetCertPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

      useEffect(() => {
        document.title = "Detergent Products";
      }, []);

  return (
    <div>
      <Header
        color="transparent"
        brand="UltraGreen Detergents"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg-detergent.jpg")} />
      <div className={classNames(classes.main)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <div className={classes.typo}>
                      <h2 className={classes.title}>
                        Certificate for Detergents
                      </h2>
                    </div>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
                With thousands of consumer products on the market, it can be
                overwhelming to know which ones are safer and healthier for your
                family and the environment as well. The UltraGreen Certificate
                does the work for you. When you see the UltraGreen Certificate
                logo on a product, you can be sure it's free from chemicals of
                concern and meets our strictest standards.{" "}
              </p>
            </div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <div className={classes.profile}>
                  <div className={classes.typo}>
                    <h3 className={classes.title}>
                      Certification by UltraGreen: what is it?
                    </h3>
                  </div>
                </div>
                <div className={classes.description}>
                  <p>
                    It's a rigorous process that consists of an independent and
                    impartial certification body assessing the conformity of a
                    product, service or system with environmental and social
                    requirements specified in a standard. At the end of the
                    certification process, UltraGreen issues a decision and when
                    positive, delivers a written assurance called UltraGreen
                    Certificate.{" "}
                  </p>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <div className={classes.profile}>
                  <div className={classes.typo}>
                    <h3 className={classes.title}>
                      Why use the UltraGreen detergents?
                    </h3>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer
              className={classes.tableCompare}
              justify="center"
              spacing={2}
            >
              <GridItem xs={12} sm={12} md={12}>
                <h4>1. Save energy (and money)</h4>
                <p>
                  UltraGreen detergents are proven to be efficient at low
                  temperatures, allowing you to save energy and money. For
                  instance, laundry detergents are tested to have the highest
                  efficiency at 30°C. Detergents are highly concentrated, i.e. a
                  small amount does the job. If you do not use more than you’re
                  supposed to, detergents can help reduce both your carbon
                  footprint and your cleaning budget.
                </p>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <h4>2. Protect your health</h4>
                <p>
                  UltraGreen detergents generally contain less problematic
                  chemicals compared to standard products, which minimises your
                  overall exposure. For instance, substances that may cause
                  cancer, change your DNA or damage fertility are strictly
                  prohibited in UltraGreen products, but not in standard
                  detergents. Allergenic substances, fragrances and
                  preservatives which are harmful for the environment or your
                  health are largely restricted, and the most problematic ones
                  are also fully banned.
                </p>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <h4>3. Sustain the environment</h4>
                <p>
                  To be awarded the label, UltraGreen detergents must not be
                  toxic to aquatic organisms and must be ready biodegradable
                  according to the OECD 301B test. Furthermore, they must be
                  free of phosphates, which stimulate algae overgrowth, threaten
                  other aquatic life and cut water oxygen levels. Moreover, the
                  label excludes disinfecting detergents which can make bacteria
                  become resistant, - hard to kill - in the long run.
                </p>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <h4>4. Minimize plastic content</h4>
                <p>
                  Every year, Europeans generate over 25.8 million tonnes of
                  plastic waste, of which only 30% is recycled. The packaging of
                  UltraGreen detergents is circular or biodegradable in order to
                  minimize the microplastics in the enviroment. UltraGreen
                  products must also be clear of plastic spheres and
                  microplastics, these tiny toxic particles of plastic which end
                  up in fish, crustaceans or planktons.
                </p>
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <div className={classes.profile}>
                  <div className={classes.typo}>
                    <h3 className={classes.title}>
                      Certification by UltraGreen: what makes it different?
                    </h3>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer
              className={classes.tableHead}
              justify="center"
              spacing={6}
            >
              <GridItem xs={3} sm={3} md={3}>
                <p>Features</p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <p>
                  <span style={spanStyleUG}>UltraGreen Certification</span>
                </p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <p>GREEN BRAND</p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <p>Ecolabel</p>
              </GridItem>
            </GridContainer>
            <GridContainer
              className={classes.tableCompare}
              justify="center"
              spacing={6}
            >
              <GridItem xs={3} sm={3} md={3}>
                <p>Performance test</p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <p>
                  Testing laundry detergents with inhouse method based on
                  2017/1218 EU std.
                </p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <p>Testing laundry detergents according to 2017/1218 EU std.</p>
              </GridItem>
            </GridContainer>
            <GridContainer
              className={classes.tableCompare}
              justify="center"
              spacing={6}
            >
              <GridItem xs={3} sm={3} md={3}>
                <p>Packaging Impact Ratio criteria</p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
            </GridContainer>
            <GridContainer
              className={classes.tableCompare}
              justify="center"
              spacing={6}
            >
              <GridItem xs={3} sm={3} md={3}>
                <p>
                  Prohibition of triclosan, parabens, formaldehyde and
                  formaldehyde releasers
                </p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
            </GridContainer>
            <GridContainer
              className={classes.tableCompare}
              justify="center"
              spacing={6}
            >
              <GridItem xs={3} sm={3} md={3}>
                <p>
                  Prohibition of direct use of ingredients that would be made by
                  electrolysis or burning (e.g. NaOH, KOH)
                </p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
            </GridContainer>
            <GridContainer
              className={classes.tableCompare}
              justify="center"
              spacing={6}
            >
              <GridItem xs={3} sm={3} md={3}>
                <p>
                  Prohibition of synthetic polymers (e.g. carbomers, acrylic
                  polymers)
                </p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <PromoterSection />
      </div>
      <Footer />
    </div>
  );
}
