import React from "react";
import { useEffect } from "react";
// import Head from "next/head";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Link from '@material-ui/core/Link';

import Icon from '@material-ui/core/Icon';
import green from '@material-ui/core/colors/green';
// import deepOrange from '@material-ui/core/colors/deepOrange';
import brown from '@material-ui/core/colors/brown';
import PromoterSection from "../Components/Sections/PromoterSection.js";

import profile from "assets/img/ultragreen-logo-big.png";

import styles from "assets/jss/material-kit-react/views/cosCertPage.js";

const useStyles = makeStyles(styles);

const spanStyleUG = {
  fontFamily: "Menco",
  fontSize: "1.1rem",
};

export default function CosCertPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

    useEffect(() => {
      document.title = "Cosmetic Products";
    }, []);

  return (
    <div>
      {/* <Head>
        <title>UltraGreen Certification Cosmetic Products</title>
      </Head> */}
      <Header
        color="transparent"
        brand="UltraGreen Cosmetics"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg-cosmetic.jpg")} />
      <div className={classNames(classes.main)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <div className={classes.typo}>
                      <h2 className={classes.title}>
                        Certificate for Naturcosmetics
                      </h2>
                    </div>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
                With thousands of consumer products on the market, it can be
                overwhelming to know which ones are safer and healthier for your
                family. The UltraGreen Certificate does the work for you. When
                you see the UltraGreen Certificate logo on a product, you can be
                sure it's free from chemicals of concern and meets our strictest
                standards for your health.{" "}
              </p>
            </div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <div className={classes.profile}>
                  <div className={classes.typo}>
                    <h3 className={classes.title}>What is a standard?</h3>
                  </div>
                </div>
                <div className={classes.description}>
                  <p>
                    It is a functional document listing all the criteria to be
                    implemented as well as the control procedures for obtaining
                    certification and the associated labels. All the standards
                    selected by UltraGreen promote practices with a positive
                    impact on the environment and society.
                  </p>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <div className={classes.profile}>
                  <div className={classes.typo}>
                    <h3 className={classes.title}>
                      Certification by UltraGreen: what is it?
                    </h3>
                  </div>
                </div>
                <div className={classes.description}>
                  <p>
                    It's a rigorous process that consists of an independent and
                    impartial certification body assessing the conformity of the
                    product, service and the manufacturing company with
                    environmental and social requirements specified in a
                    standard. At the end of the certification process,
                    UltraGreen issues a decision and when positive, delivers a
                    written assurance called UltraGreen Certificate.{" "}
                  </p>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <div className={classes.profile}>
                  <div className={classes.typo}>
                    <h3 className={classes.title}>
                      Browse the latest products:
                    </h3>
                  </div>
                </div>
                <div className={classes.description}>
                  <a
                    href="https://skinlyzer.com/category/UltraGreen"
                    target="_blank"
                  >
                    <img src="skinlyzer-logo.png" height="60px" />
                  </a>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <div className={classes.profile}>
                  <div className={classes.typo}>
                    <h3 className={classes.title}>
                      Certification by UltraGreen: what makes it different?
                    </h3>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer
              className={classes.tableHead}
              justify="center"
              spacing={6}
            >
              <GridItem xs={3} sm={3} md={3}>
                <p>Features</p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <p>
                  <span style={spanStyleUG}>UltraGreen Certification</span>
                </p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <p>
                  ISO 16128 Standard
                  <br />
                  e.g. Naturix
                </p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <p>GREEN BRAND</p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <p>NATRUE / ECOCERT / COSMOS</p>
              </GridItem>
            </GridContainer>
            <GridContainer
              className={classes.tableCompare}
              justify="center"
              spacing={6}
            >
              <GridItem xs={3} sm={3} md={3}>
                <p>Target group</p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <p>Manufacturers and end consumers</p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <p>Manufacturers</p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <p>End consumers</p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <p>Manufacturers and end consumers</p>
              </GridItem>
            </GridContainer>
            <GridContainer
              className={classes.tableCompare}
              justify="center"
              spacing={6}
            >
              <GridItem xs={3} sm={3} md={3}>
                <p>
                  Prohibition of ingredients that would be legally allowed in
                  cosmetics
                </p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[400] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
            </GridContainer>
            <GridContainer
              className={classes.tableCompare}
              justify="center"
              spacing={6}
            >
              <GridItem xs={3} sm={3} md={3}>
                <p>Prohibition of petrochemical fractions</p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
            </GridContainer>
            <GridContainer
              className={classes.tableCompare}
              justify="center"
              spacing={6}
            >
              <GridItem xs={3} sm={3} md={3}>
                <p>Prohibition of aluminum compounds</p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
            </GridContainer>
            <GridContainer
              className={classes.tableCompare}
              justify="center"
              spacing={6}
            >
              <GridItem xs={3} sm={3} md={3}>
                <p>
                  Prohibition of{" "}
                  <Link href="/phenoxyethanol">Phenoxyethanol</Link>
                </p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[500] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
            </GridContainer>
            <GridContainer
              className={classes.tableCompare}
              justify="center"
              spacing={6}
            >
              <GridItem xs={3} sm={3} md={3}>
                <p>Certification</p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[400] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[400] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
            </GridContainer>
            <GridContainer
              className={classes.tableCompare}
              justify="center"
              spacing={6}
            >
              <GridItem xs={3} sm={3} md={3}>
                <p>Definition of natural ingredients</p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[400] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
            </GridContainer>
            <GridContainer
              className={classes.tableCompare}
              justify="center"
              spacing={6}
            >
              <GridItem xs={3} sm={3} md={3}>
                <p>Definition of derived natural and organic ingredients</p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[400] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
            </GridContainer>
            <GridContainer
              className={classes.tableCompare}
              justify="center"
              spacing={6}
            >
              <GridItem xs={3} sm={3} md={3}>
                <p>
                  Definition of derived natural and organic ingredients with
                  >50% natural/organic content
                </p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  help
                </Icon>
              </GridItem>
            </GridContainer>
            <GridContainer
              className={classes.tableCompare}
              justify="center"
              spacing={6}
            >
              <GridItem xs={3} sm={3} md={3}>
                <p>
                  Certification requirement of product natural contents without
                  water >95%
                </p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
            </GridContainer>
            <GridContainer
              className={classes.tableCompare}
              justify="center"
              spacing={6}
            >
              <GridItem xs={3} sm={3} md={3}>
                <p>
                  Approve and define reef-safe chemical UV filters, and products
                  as well.
                </p>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: green[500] }} fontSize="large">
                  check_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
                <Icon style={{ color: brown[400] }} fontSize="large">
                  remove_circle
                </Icon>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <PromoterSection />
      </div>
      <Footer />
    </div>
  );
}
