import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
// import Icon from '@material-ui/core/Icon';
// import green from '@material-ui/core/colors/green';
// import deepOrange from '@material-ui/core/colors/deepOrange';
// import brown from '@material-ui/core/colors/brown';
import profile from "assets/img/ultragreen-logo-big.png";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

import image1 from "assets/img/globe-02.png";

const useStyles = makeStyles(styles);

// const spanStyleUG = {
//     fontFamily: "Menco",
//     fontSize: "1.1rem",
// };

export default function ImpressumPage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );
    return (
        <div>
            <Header
                color="transparent"
                brand="UltraGreen Certifications"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/bg04.jpg")} />
            <div className={classNames(classes.main)}>
                <div>
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={8}>
                                <div className={classes.profile}>
                                    <div>
                                        <img src={profile} alt="..." className={imageClasses} />
                                    </div>
                                    <div className={classes.name}>
                                        <div className={classes.typo}>
                                            <h2 className={classes.title}>Company information</h2>
                                            <h4 className={classes.description}>
                                    UltraGreen Kft.
          </h4>
                                <h5 className={classes.description}>
                                    1039 Hungary, Budapest, Batthyány utca 35/B.
          </h5>
                                <h5 className={classes.description}>
                                    Vat: HU28839792
          </h5>
                                        </div>
                                    </div>
                                </div>
                            </GridItem>
                        </GridContainer>
                        <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <div className={classes.profile2}>
                  <div>
                    <img src={image1} alt="..." />
                  </div>
                </div>
              </GridItem>
            </GridContainer>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
