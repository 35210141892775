import React from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import ReactGA from 'react-ga4';
import withTracker from "utils/withTracker";

import "assets/scss/material-kit-react.scss?v=1.8.0";

// pages for this product
// import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ContactPage from "views/Contact/ContactPage.js";
import ImpressumPage from "views/Contact/ImpressumPage.js";
import PrivacyPage from "views/Contact/PrivacyPage.js";
// import LoginPage from "views/LoginPage/LoginPage.js";
import CosCertPage from "views/Cosmetics/CosCertPage.js";
import PhenoxyethanolPage from "views/Cosmetics/PhenoxyethanolPage.js";
import DetCertPage from "views/Detergents/DetCertPage.js";
import PackPage from "views/Packaging/PackPage.js";
import GranulePage from "views/Granules/GranulePage.js";
import SearchPage from "views/Database/SearchPage.js";
import Certificate from "views/LandingPage/Certificate.js";
import HowMuchLaundryDetergentShouldYouUse from "views/Detergents/HowMuchLaundryDetergentShouldYouUse.js";

var hist = createBrowserHistory();

const TRACKING_ID = "G-GPX0DR1JT8";

ReactGA.initialize(TRACKING_ID, {
  debug: false,
});

// ReactGA.initialize("UA-186047649-1", {
//   debug: false,
// });

    // useEffect(() => {
    //   ReactGA.initialize(TRACKING_ID);
    //   ReactGA.send({
    //     hitType: "pageview",
    //     page: "/",
    //     title: "Landing Page",
    //   });
    // }, []);

ReactDOM.render(

    <Router history={hist}>
      <Switch>
        <Route path="/cert55" component={Certificate} />
        <Route path="/cosmetics" component={withTracker(CosCertPage)} />
        <Route path="/detergents" component={withTracker(DetCertPage)} />
        <Route path="/packaging" component={withTracker(PackPage)} />
        <Route path="/granules" component={withTracker(GranulePage)} />
        <Route
          path="/phenoxyethanol"
          component={withTracker(PhenoxyethanolPage)}
        />
        <Route
          path="/HowMuchLaundryDetergentShouldYouUse"
          component={withTracker(HowMuchLaundryDetergentShouldYouUse)}
        />

        {/* <Route path="/components55" component={withTracker(Components)} /> */}
        {/* <Route path="/landing-page" component={withTracker(LandingPage)} /> */}
        {/* <Route path="/contact" component={withTracker(ContactPage)} /> */}
        <Route path="/impressum" component={withTracker(ImpressumPage)} />
        <Route path="/privacy" component={withTracker(PrivacyPage)} />
        {/* <Route path="/login-page" component={LoginPage} /> */}
        <Route path="/search" component={withTracker(SearchPage)} />
        <Route path="/" component={withTracker(LandingPage)} />
      </Switch>
    </Router>,
  document.getElementById("root")
);
