import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import team1 from "assets/img/packings.jpg";
import team2 from "assets/img/cosmetics.jpg";
import team3 from "assets/img/detergents.jpg";

const useStyles = makeStyles(styles);

export default function CertificateSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Certification for</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={team1} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Packaging
                <br />
                <small className={classes.smallTitle}>and plastic granules</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                Biodegradable biopolymers not only for food container but also for cosmetic and detergent packaging. 
                Coming soon!
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={team2} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Cosmetics
                <br />
                <small className={classes.smallTitle}>naturcosmetics</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                Cosmetic products which you can be sure are free from chemicals 
                of concern and meets our strictest standards for your health.<br/>
                <a href="/cosmetics">See more information!</a>
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={team3} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Detergents
                <br />
                <small className={classes.smallTitle}>household and industrial products</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                Biodegradable and safe detergents which you can be sure it's free from chemicals of 
                concern and meets our strictest standards not only for your health, but also for the environment.<br/>
                <a href="/detergents">See more information!</a>
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
