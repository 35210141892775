import React, { useState, useEffect } from "react";
import API from '../../utils/api';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Search from "@material-ui/icons/Search";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import Page1Section from "./Sections/Page1Section.js";
import CertificateSection from "./Sections/CertificateSection.js";
import PromoterSection from "../Components/Sections/PromoterSection.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    API.post( '/ultragreen/hb' ).then(
        function( response ) {
          console.log( response );
        } )
        .catch( function( error ) {
            console.log( error );
        } );
  }, []);

  return (
    <div>
      <Header
        color="rose"
        routes={dashboardRoutes}
        brand="UltraGreen Certifications"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/bg05.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <h1 className={classes.title}>Do you need a really environmentally and human friendly product?</h1>
              <h4>
              UltraGreen provides you with suitable solutions to choose good environmental and human 
              health products in the fields of cosmetics, detergents and plastic packages. 
              Our teams help you choose the certifications that are best suited to your needs.
              </h4>
              <br />
              {/* <Tooltip
                id="brosura-letoltes"
                title="Tájékoztató füzet letöltése!"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  color="primary"
                  size="lg"
                  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-download" />
                  Brosúra letöltése
              </Button>
              </Tooltip> */}
            </GridItem>
          </GridContainer>
          
        </div>
      </Parallax>
      <div className={classNames(classes.main)}>
      <Page1Section />
        <div className={classes.container}>
          <CertificateSection />
        </div>
        <div className={classes.container}>
          <PromoterSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
